import http from '../utils/http-common';

/**
 * Main service class
 */
class Service {

    /**
     * Base path to controller
     */
    basePath = null;

    /**
     * Is new record flag
     */
    isNewRecord = true;

    /**
     * Response data
     */
    responseData = {};

    /**
     * Errors after validate
     */
    errors = {};

    /**
     * total count on page
     */
    metaTotal = 0;

    /**
     * Count rows on page
     */
    metaPerPage = 0;

    /**
     * Expanded attributes
     */
    expand = [];

    /**
     * Whether to make a request for lists
     */
    loadLists = [];

    /**
     * Loaded lists from set
     */
    lists = {};

    /**
     * Labels from lists
     */
    labels = {};

    /**
     * Values from lists
     */
    values = {};

    /**
     * Settings data: labels, rules, lists
     */
    _setData = {};

    /**
    * Whether to make a request for settings
    */
    _isLoadSet = false;

    /**
     * Init constructor attributes
     */
    _attributes = null

    /**
     * Set attributes when initializing a class
     * @param {Object} attributes 
     */
    constructor(attributes) {
        this.initAttributes(attributes);
    }

    /**
     * Initialization constructor attributes
     * @param {Object} attributes 
     */
    initAttributes(attributes) {
        this._attributes = attributes;

        if (attributes) {
            for (var i in attributes) {
                this[i] = attributes[i];
            }
        }
    }

    /**
     * Get new object
     * @returns {Service}
     */
    newObject() {
        return new Service();
    }

    /**
     * Get new object with attributes
     * @returns {Service}
     */
    newObjectAttr() {
        let newObject = this.newObject();
        newObject.initAttributes(this._attributes);

        return newObject;
    }

    /**
     * Reset to initialized data
     */
    reset() {
        let newObject = this.newObjectAttr();

        for (var i in this) {
            if (newObject[i] === undefined) {
                delete this[i];
            } else {
                this[i] = newObject[i];
            }
        }
    }

    /**
     * Routes
     * @returns {array}
     */
    getRoutes() {
        return {
            set: '/' + this.basePath + '/set',
            index: '/' + this.basePath,
            allList: '/' + this.basePath + '/all-list',
            store: '/' + this.basePath,
            show: '/' + this.basePath + '/{id}',
            update: '/' + this.basePath + '/{id}',
            delete: '/' + this.basePath + '/{id}',
            deleteMass: '/' + this.basePath + '/destroy',
        }
    }

    /**
     * Get all list items
     * @returns {Object}
     */
    async set() {
        if (Object.keys(this._setData).length > 0) {
            return this._setData;
        }

        return await http.get(this.getRoutes().set).then(async response => {
            if (response.data.errors) {
                this.errors = this.arrOneLevel(response.data.errors);
            } else {
                this._setData = response.data.data;

                await this.getLists();
                this.getLabels();
                this.getValues();

                return this._setData;
            }
        });
    }

    /**
     * Get _isLoadSet flag
     * @returns {bool}
     */
    getIsLoadSet() {
        // если указано запрашивать списки, то set тоже запрашиваем
        if (Object.keys(this.loadLists).length > 0) {
            return true;
        }

        return this._isLoadSet;
    }

    /**
     * Get and set lists
     * @returns {array}
     */
    async getLists() {
        if (Object.keys(this.loadLists).length === 0) {
            return {};
        }

        var set = await this.set();

        this.lists = {};

        if (set.lists) {
            for (var i in set.lists) {
                var list = set.lists[i];

                if (this.loadLists.includes(i) && !list.items && list.url) {
                    var params = {};
                    // проставляем зависимости в список параметров к запросу
                    if (list.depends) {
                        for (let j in list.depends) {
                            if (list.depends[j].value) { // статическая зависимость
                                params[list.depends[j].key] = list.depends[j].value;
                            } else if (list.depends[j].attr) { // динамическая зависимость
                                // todo: Добавить слушатель изменений на переменную - при изменении перезапрашивать список
                                params[list.depends[j].key] = this[list.depends[j].attr];
                            }
                        }
                    }

                    list.items = await http.get(list.url, { params: params }).then(response => {
                        if (response.data.errors) {
                            this.errors = this.arrOneLevel(response.data.errors);

                            return {};
                        } else {
                            return response.data.data;
                        }
                    });
                }

                this.lists[i] = list.items ? list.items : [];
            }

        }

        return this.lists;
    }

    /**
     * Get and set labels
     * @returns {Object}
     */
    async getLabels() {
        var set = await this.set();

        if (set.labels) {
            this.labels = set.labels;
        }

        return this.labels;
    }

    /**
     * Get and set values
     * @returns {Object}
     */
    async getValues() {
        var lists = await this.getLists();

        if (lists) {
            for (var i in lists) {
                if (lists[i].items) {
                    this.values[i] = this.getNameById(lists[i].items, this[i]);
                }
            }
        }

        return this.values;
    }

    /**
     * Get all items
     * @returns {array}
     */
    async all() {
        return await http.get(this.getRoutes().index, { params: this.prepareRequest() }).then(async response => {
            this.responseData = response.data;

            if (response.data.errors) {
                this.errors = this.arrOneLevel(response.data.errors);
            } else {
                if (response.data.meta) {
                    this.metaTotal = response.data.meta.total;
                    this.metaPerPage = response.data.meta.per_page;
                }

                var data = response.data.data;
                var collection = [];
                for (var i in data) {
                    var service = this.newObject();
                    service.isNewRecord = false;

                    for (var j in data[i]) {
                        service[j] = data[i][j];
                    }

                    if (this.getIsLoadSet()) {
                        service._setData = await this.set();
                        service.lists = this.lists;
                        service.labels = this.labels;
                        service.values = this.values;
                    }

                    collection.push(service);
                }

                return collection;
            }
        });
    }

    /**
     * Get all list items
     * @returns {array}
     */
    async allList() {
        return await http.get(this.getRoutes().allList, { params: this.prepareRequest() }).then(response => {
            if (response.data.errors) {
                this.errors = this.arrOneLevel(response.data.errors);
            } else {
                return response.data.data;
            }
        });
    }

    /**
     * Get item by id
     * @param {string | RouteParamValue[]} id
     * @returns {Service}
     */
    async show(id) {
        id = id ? id : this.id;

        await http.get(this.getRoutes().show.replace('{id}', id), { params: this.prepareRequest() }).then(async response => {
            this.applyResponse(response, this)

            if (this.getIsLoadSet()) {
                await this.set();
            }
        });

        return this;
    }

    /**
     * Insert or update item
     * @param {array} attributes 
     * @returns {bool}
     */
    async save(attributes) {
        if (attributes) {
            var request = {}

            for (let attr of attributes) {
                request[attr] = this[attr]
            }
        } else {
            var request = this.prepareRequest();
        }

        if (this.isNewRecord) {
            let response = await http.post(this.getRoutes().store, request).then(response => {
                this.applyResponse(response, this)

                return response
            })

            return response
        } else {
            await http.put(this.getRoutes().update.replace('{id}', this.id), request).then(response => { this.applyResponse(response, this) });
        }

        return true;
    }

    /**
     * Delete item by id
     * @returns {bool}
     */
    async delete(id) {
        id = id ? id : this.id;

        await http.delete(this.getRoutes().delete.replace('{id}', id)).then(response => {
            if (response.data.errors) {
                this.errors = this.arrOneLevel(response.data.errors);
            } else {
                // clear object after delete
                this.isNewRecord = true;
                this.errors = {};
                for (var i in this) {
                    if (i !== 'basePath' && i !== 'isNewRecord' && i !== 'errors') {
                        delete this[i];
                    }
                }
            }
        });

        return true;
    }

    /**
     * Delete mass items by id
     * @returns {bool}
     */
    async deleteMass(id) {
        await http.delete(this.getRoutes().deleteMass, { params: { id: id } }).then(response => {
            if (response.data.errors) {
                this.errors = this.arrOneLevel(response.data.errors);
            } else {
                this.errors = {};
            }
        });

        return true;
    }

    /**
     * Parse params from url and apply
     */
    parseRequest() {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((value, key) => {
            var realKey = key.replace('[]', '');

            if (value === '') {
                return;
            }

            // если в строке число, то к нему и приводим
            value = isNaN(+value) ? value : +value;

            if (key === 'per_page') {
                this.metaPerPage = value;
            } else if (realKey === key) {
                this[key] = value;
            } else {
                this[realKey] = this[realKey] ? this[realKey] : [];
                this[realKey].push(value);
            }
        });
    }

    /**
     * Prepare this object for send request
     * @param {bool} isRouteArrays 
     * @returns {Object}
     */
    prepareRequest(isRouteArrays) {
        var request = Object.assign({}, this);

        // удаляем дефолтные переменные, чтобы не отправлялись в запросе
        delete request.basePath;
        delete request.isNewRecord;
        delete request.responseData;
        delete request.errors;
        delete request.metaTotal;
        delete request.metaPerPage;
        delete request.expand;
        delete request.loadLists;
        delete request.lists;
        delete request.labels;
        delete request.values;

        // удаляем переменные начинающиеся с _
        for (var i in request) {
            if (i.startsWith('_')) {
                delete request[i]
            }
        }

        if (this.metaPerPage) {
            request.per_page = this.metaPerPage;
        }

        // обработка массивов для роутов, т.к. там нужно передавать со скобками
        if (isRouteArrays) {
            for (var i in request) {
                if (Array.isArray(request[i])) {
                    request[i + '[]'] = request[i]
                    delete request[i]
                }
            }
        } else { // то что иначе не идет в обработку запроса для роутов, т.к. отправляется всегда
            if (Object.keys(this.expand).length > 0) {
                request.expand = this.expand.join();
            }
        }

        return request;
    }

    /**
     * Apply response data to service
     */
    applyResponse(response, service) {
        this.responseData = response.data;

        if (response.data.errors) {
            service.errors = service.arrOneLevel(response.data.errors);
        } else {
            var data = response.data.data;
            for (var i in data) {
                service[i] = data[i];
            }

            service.isNewRecord = false;
            service.errors = {};
        }
    }

    /**
     * Change array to one level
     * @param {array} arr 
     * @returns {array}
     */
    arrOneLevel(arr) {
        for (var i in arr) {
            if (typeof arr[i] !== 'string') {
                arr[i] = arr[i].join(', ');
            }
        }

        return arr;
    }

    /**
     * Get name by id in Object
     * @param {array} items 
     * @param {int} id 
     * @returns {string}
     */
    getNameById(items, id) {
        for (var i in items) {
            if (items[i].id === id) {
                return items[i].name;
            }
        }
    }
}

export default Service;