import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { createI18n } from "vue-i18n";
import LoadScript from "vue-plugin-load-script";
import { messages } from "./lang/locales";
import { dateTimeFormats } from "./lang/locales";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import StyleClass from "primevue/styleclass";
import Maska from 'maska'
import jsPDF from 'jspdf';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/themes/lara-light-purple/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const i18n = createI18n({
  locale: store.state.locale ? store.state.locale : "ru", // set locale
  fallbackLocale: "us", // set fallback locale
  messages, // set locale messages
  dateTimeFormats,
});

createApp(App)
  .use(store)
  .use(PrimeVue)
  .directive("styleclass", StyleClass)
  .use(ConfirmationService)
  .use(jsPDF)
  .use(ToastService)
  .use(router)
  .use(Maska)
  .use(i18n)
  .use(LoadScript)
  .directive("tooltip", Tooltip)
  .mount("#app");
