import { createStore } from "vuex";
import InvoiceService from "../../src/modules/invoice/service/InvoiceService";

export default createStore({
  state() {
    return {
      service: new InvoiceService(),
      // service: [],
    };
  },
  mutations: {
    setService(state, payload) {
      // state.service.push(payload)
    },
  },
  actions: {},
  modules: {},
});
