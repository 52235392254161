<template>
  <div class="content-page">
    <div class="content">
      <TopSideBar />
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>

  <Toast position="bottom-left" group="bl" />
  <ConfirmDialog></ConfirmDialog>
  <!-- <SmsStack></SmsStack> -->
</template>

<script>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import TopSideBar from "../components/TopSideBar.vue";
// import SmsStack from '../components/sms/SmsStack'

export default {
  components: { Toast, ConfirmDialog, TopSideBar /*, SmsStack*/ },
};
</script>

<style lang="scss" scoped>
button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
</style>
