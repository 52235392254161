import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('../views/Home.vue'),
    meta: {
      layout: "main",
      auth: false,
    },
  },
  {
    path: "/amount",
    name: "InvoiceAmountCreate",
    component: () => import('../modules/invoice/views/amount/Create.vue'),
    meta: {
      layout: "main",
      auth: false,
    },
  },
  {
    path: "/recipient/details",
    name: "InvoiceRequisitesCreate",
    component: () => import('../modules/invoice/views/recipientDetails/Create.vue'),
    meta: {
      layout: "main",
      auth: false,
    },
  },
  // test
  {
    path: "/tabs",
    name: "Tabs",
    component: () => import('../modules/test/Tabs.vue'),
    meta: {
      layout: "main",
      auth: false,
    },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import('../modules/test/About.vue'),
  //   meta: {
  //     layout: "main",
  //     auth: false,
  //   },
  // },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: () => import('../modules/test/Contact.vue'),
  //   meta: {
  //     layout: "main",
  //     auth: false,
  //   },
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
