import Service from "../../../services/Service";

class InvoiceService extends Service {
  newObject() {
    return new InvoiceService();
  }

  validate() {
    this.errors = {};

    if (this.step == 1) {
      this.errors = {};

      if (!this.providerValue) {
        this.errors.providerValue = "Укажите название поставщика";
      }
      if (!this.сustomerValue) {
        this.errors.сustomerValue = "Укажите название клиента";
      }
      for (let product of this.products) {
        if (!(product.name && product.qty && product.price)) {
          this.errors.products = "Укажите товар или услугу";
        }
      }
      if (!this.number) {
        this.errors.number = "Укажите номер счета";
      }
      if (!this.date) {
        this.errors.date = "Укажите дату";
      }
    }

    if (this.step == 2) {
      this.errors = {};
      
      if (!this.providerValue) {
        this.errors.providerValue = "Укажите название поставщика";
      }
      if (!this.providerAddress) {
        this.errors.providerAddress = "Укажите адрес";
      }
      if (!this.providerPhone) {
        this.errors.providerPhone = "Укажите телефон";
      }
      if (!this.providerInn) {
        this.errors.providerInn = "Укажите ИНН";
      }
      if (!this.providerBank) {
        this.errors.providerBank = "Укажите Банк";
      }
      if (!this.providerBic) {
        this.errors.providerBic = "Укажите БИК";
      }
      if (!this.providerCorrAccount) {
        this.errors.providerCorrAccount = "Укажите корр. счёт";
      }
  
      if (!this.providerFullName) {
        this.errors.providerFullName = "Укажите ФИО получателя";
      }
      if (!this.providerPositionMain) {
        this.errors.providerPositionMain = "Укажите должность получателя";
      }
  
      if (this.isSignAccountChief) {
        if (!this.providerFullNameAccountChief) {
          this.errors.providerFullNameAccountChief = "Укажите ФИО бухгалтера";
        }
        if (!this.providerPositionAccountChief) {
          this.errors.providerPositionAccountChief = "Укажите должность";
        }
      }
  
      if (!this.providerBeneficiaryAccount) {
        this.errors.providerBeneficiaryAccount = "Счёт получателя";
      }
    }

    if (this.step == 3) {
      if (!this.сustomerValue) {
        this.errors.сustomerValue = "Укажите наименование плательщика";
      }
      if (!this.customerInn) {
        this.errors.customerInn = "Укажите ИНН";
      }
      if (!this.сustomerAddress) {
        this.errors.сustomerAddress = "Укажите адресс";
      }
      if (!this.сustomerPhone) {
        this.errors.сustomerPhone = "Укажите телефон";
      }
    }
  }
}

export default InvoiceService;
