<template>
  <div class="navbar navbar-expand-lg shadow-sm">
    <div class="container">
      <div class="my-2" style="max-width: 150px">
        <img src="/img/invocelogo.png" class="img-fluid" />
      </div>
    </div>
  </div>
  <div class="main-invoice">
    <div class="container my-5">
      <h1 class="fw-bold main-invoice-h1">
        {{ $t("Online service billing") }}
      </h1>
      <p class="fs-5" style="font-family: 'Montserrat Alternates', sans-serif;">{{ $t('Issue an invoice to a client online in one minute') }}</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
