export const ru = {
    // Collections
    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентябрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',

    'of January': 'Января',
    'of February': 'Февраля',
    'of March': 'Марта',
    'of April': 'Апреля',
    'of May': 'Мая',
    'of June': 'Июня',
    'of July': 'Июля',
    'of August': 'Августа',
    'of September': 'Сентября',
    'of October': 'Октября',
    'of November': 'Ноября',
    'of December': 'Декабря',

    'Mo': 'Пн',
    'Tu': 'Вт',
    'We': 'Ср',
    'Th': 'Чт',
    'Fr': 'Пт',
    'Sa': 'Сб',
    'Su': 'Вс',

    // Other
    'Account comment:': 'Комментарий к счёту:',
    'Account No.': 'Счет №',
    'Account number': 'Номер счёта',
    'Address': 'Адрес',
    'address': 'адрес',
    'Add the signature of the chief accountant': 'Добавить подпись главного бухгалтера',
    'Almost done, it remains to double-check the data': 'Почти готово, осталось перепроверить данные',
    'An invoice for payment №': 'Счет на оплату №',

    'Bank': 'Банк',
    'Bank details': 'Реквизиты банка',
    'Base': 'Основание',
    'Beneficiary account': 'Счёт получателя',
    'BIC': 'БИК',
    'Buyer': 'Покупатель',
    'Buyers details': 'Реквизиты покупателя',

    'C/a': 'К/с',
    'Contact details': 'Контактные данные',
    'Client': 'Клиент',
    'Comment': 'Комментарий',
    'conv. units': 'усл. ед.',
    'Create an account': 'Сформировать счет',
    'Customer': 'Заказчик',

    'dm³': 'дм³',
    'Do not base': 'Не выводить основание',
    
    'Enter comment text': 'Введите текст комментария',
    'Enter justification text': 'Введите текст обоснования',
    'Enter the title': 'Введите название',
    'Executor': 'Исполнитель',
    'Exempted from VAT on the basis of Article 145 of the Tax Code of the Russian Federation': 'Освобожден от уплаты НДС на основании статьи 145 НК РФ',
    
    'from': 'от',
    'for the amount': 'на сумму',
    'Full name': 'ФИО',
    'Full name account chief': 'ФИО бухгалтера',
    
    'hour': 'час',
    
    'Invoice amount': 'Сумма счета',
    'INN': 'ИНН',
    'Invoice with VAT': 'Выставить с НДС',
    'Issue an invoice to a client online in one minute': 'Выставляйте счёт клиенту онлайн за одну минуту',
    
    'kg.': 'кг.',
    'KPP': 'КПП.',
    
    'Label': 'Наименование',
    'Licensor': 'Лицензиар',
    'liter': 'литр',
    
    'm.': 'м.',
    'm²': 'м²',
    'm³': 'м³',
    
    'Not chosen': 'Не выбрано',

    'Online service billing': 'Онлайн-сервис выставления счетов',
    
    'pack.': 'упак.',
    'Payer details': 'Реквизиты плательщика',
    'Payees details': 'Реквизиты получателя платежа',
    'Payees bank': 'Банк получателя',
    'PC.': 'шт.',
    'Position': 'Должность',
    'Phone': 'Телефон',
    'Prev': 'Назад',
    'Prepare an invoice signed and stamped free in 4 minutes': 'Подготовьте счёт с подписью и печатью бесплатно за 4 минуты',
    'Price': 'Цена',
    'Product or service': 'Товар или услуга',
    'Provider': 'Поставщик',
    'pog. m.': 'пог. м.',
    
    'Recipient': 'Получатель',
    'Recipient details': 'Реквизиты получателя',
    
    'Salesman': 'Продавец',
    'Save and Continue': 'Сохранить и продолжить',
    'Select the reason for the absence of VAT or enter your own:': 'Выберите причину отсутствия НДС или укажите свою:',
    'set.': 'компл.',
    'Signatures and seal': 'Подписи и печать',
    'Signature on the account': 'Подпись на счёте',
    'Specify your option': 'Укажите свой вариант',
    'Sum': 'Сумма',
    
    'The price includes VAT': 'Цена включает НДС',
    'The operation is not subject to VAT on the basis of article 149 of the Tax Code of the Russian Federation': 'Операция не подлежит обложению НДС на основании статьи 149 НК РФ',
    'tel:': 'тел',
    'Total': 'Итого',
    'Total, incl. VAT': 'Итого, вкл. НДС',
    'Total items': 'Всего наименований',
    
    'VAT': 'НДС',
    'VAT is not charged in connection with the application of the simplified tax system on the basis of Article 346.11 of the Tax Code of the Russian Federation': 'НДС не облагается в связи с применением УСН на основании статьи 346.11 НК РФ',
    'VAT is not charged in connection with the application of PSN on the basis of article 346.43 of the Tax Code of the Russian Federation': 'НДС не облагается в связи с применением ПСН на основании статьи 346.43 НК РФ',
    'VAT is not charged in connection with the application of UTII on the basis of article 346.26 of the Tax Code of the Russian Federation': 'НДС не облагается в связи с применением ЕНВД на основании статьи 346.26 НК РФ',
    'VAT is not charged in accordance with sub. 26 p. 2 art. 149 Tax Code of the Russian Federation': 'НДС не облагается в соответствии с подп. 26 п. 2 ст. 149 НК РФ',
    
    'u': 'ед.',
    'U': 'Ед',
    
    'Your variant': 'Свой вариант',

    'Without VAT': 'Без НДС',
    
    'Quantity': 'Количество',
    'Qty': 'Кол-во',
}